import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import PolicyTemplate from '../../templates/pages/policy';

const TermsOfUse = ( { data } ) => {
	const { file: { childMarkdownRemark: { frontmatter: { copy } } } } = data;

	return (
		<PolicyTemplate
			title="terms and condistions"
			copy={ copy }
			heading="Terms & Conditions"
		/>
	);
};

TermsOfUse.propTypes = { data: PropTypes.object };

export default TermsOfUse;

export const query = graphql`
query Terms {
  file(relativePath: {eq: "policy/terms.md"}) {
    childMarkdownRemark {
      frontmatter {
        copy
      }
    }
  }
}
`;
